const test = (val) => {
    console.log(val);
}

$(document).ready(() => {

    $('#numCoupon').focus();

    $('#numCoupon').click(() => {

        $('#numCoupon').keyboard({
            layout : 'fr-azerty-perso',
            autoAcceptOnEsc : true,
            autoAccept : true,
            autoAcceptOnValid : true,   
            usePreview : true,
            tabNavigation : true,
            enterNavigation : true,
        });

        $('#numCoupon').focus();
    });
})


